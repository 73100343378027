import React from 'react';
import PropTypes from 'prop-types';
import WithLayout from '../WithLayout';
import { conf } from '../../domain/services/configService';
import { BlankTemplateContent } from '../../components/BlankTemplateContent';
import { useJsDependencies } from '../../hooks/useJsDependencies';

// https://www.nationalgeographic.co.uk/explore-the-uk-your-suv is a special case where
// the whole page is composed by using pestle modules. It can't work unless it's refactored.

const Page = ({ content }) => {
  let { customBody } = content;
  if (content.customBody.indexOf('src="/sites/') !== -1) {
    customBody = content.customBody.replace(
      /src="\/sites\/[a-z]+\/files\//g,
      `src="//${conf.staticDomain}/files/`
    );
  }

  const areJsDepsLoaded = useJsDependencies(
    {
      'https://ajax.googleapis.com/ajax/libs/jquery/3.6.0/jquery.min.js': () =>
        'jQuery' in window
    },
    { returnValueForSSR: true }
  );

  return areJsDepsLoaded ? (
    <article data-node-id={content.id} className="node node--type-page">
      <BlankTemplateContent body={customBody} script={content.customScript} />
    </article>
  ) : null;
};

Page.propTypes = {
  content: PropTypes.objectOf(PropTypes.any).isRequired
};

export default WithLayout(Page);
